.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/*This affects the navigation links on the left*/
.MuiListItemText-primary {
  font-weight: 500 !important;
  color: black;
}
